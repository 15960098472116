import Model from '@/models/Model'
import { parse, isValid } from 'date-fns';
import { constructWorkingHours, getDefaultWorkingHours } from '@/helpers/working-hours.js';
import { getZonedDayFromUtcDay, getZonedTimeFromUtcTime } from '@/helpers/dates';
import { format } from '@/helpers/dates/format';

const TS = {
  id: 'id',
  companyName: 'name',
  phoneNumber: 'telephone_number',
  streetName: 'address_street',
  houseNumber: 'address_house_number',
  postalCode: 'address_zip_code',
  place: 'address_city',
  canInviteToCompany: 'can_invite_to_company',
  logo: 'logo',
  planId: 'plan_id',
  paid: 'paid',
};

const parseDate = (date, format = 'yyyy-MM-dd') => {
  const parsedDate = date ? parse(date, format, new Date()) : null;
  return parsedDate && isValid(parsedDate) ? parsedDate : null;
};

class CompanyModel extends Model {
  constructor(data) {
    super(data)

    this.canCreateRegistrationType = data?.can_create_registration_type

    for (let key in TS) {
      this[key] = data[TS[key]]
    }

    let properties = typeof data.properties === 'string' ? JSON.parse(data.properties) : data.properties

    this.properties = properties
      ? {
        specialism: properties.specialism,
        amountPeople: properties.amount_people,
        createdByFunction: properties.created_by_function,
        usageReasons: properties.usage_reasons || [],
        planningDays: properties.planning_conf_add_to_project_days,
        planningChat: properties.planning_conf_add_to_project_chat,
        planningRegistrations: properties.planning_conf_add_from_project_registrations,
        enableProjectTemplates: properties.enable_project_templates || false,
        registrationFromTill: properties.registration_from_till || false,
        useProjectAutonum: properties.use_project_autonum || false,
        projectAutonumStr: properties.project_autonum_str || '',
        projectAutonumStart: properties.project_autonum_start || '',
        projectAutonumInt: properties.project_autonum_int || null
      }
      : null

    this.customColorLogoProjects = data.properties?.custom_color_logo_projects ?? null;
    this.customColorTeamchatsColor = data.properties?.custom_color_teamchats_color ?? null;
    this.customColorProjectsChatsSearch = data.properties?.custom_color_projects_chats_search ?? null;
    this.customColorHoverColor = data.properties?.custom_color_hover_color ?? null;
    this.customProjectsLogo = data.properties?.custom_projects_logo ?? null;

    this.weekWorkingHours = data.week_working_hours || 40
    this.workingHours = data.properties?.company_work_hours
      ? constructWorkingHours(data.properties.company_work_hours)
      : getDefaultWorkingHours();

    this.realtimeTranslations = Boolean(data.properties?.realtime_translations);

    this.importPdf = Boolean(data.properties?.import_pdf);

    const date = new Date();
    const time = data.properties?.notification_hours_time;

    this.notificationHoursTime = time
      ? format(getZonedTimeFromUtcTime({ date, time }), 'HH:mm')
      : null;

    this.notificationHoursDays = time && data.properties?.notification_hours_days
        ? data.properties.notification_hours_days.map(
          (day) => getZonedDayFromUtcDay({ date, day, time })
        )
        : [];

    this.formsTrialEnd = parseDate(data.properties?.forms_trial_end);
    this.formsTrialDaysLeft = data.properties?.forms_trial_days_left;
    this.formsEnabled = data.properties?.forms_enabled ?? false;

    this.isOnedriveEnabled = data.properties?.is_one_drive_enabled ?? false;

    this.planExpireDate = data.plan_expire;
    this.licenseMaxUsers = data.license_max_users || 0;
    this.contractPlanId = data.contract_plan_id;
    this.contractEndDate = data.contract_end_date;
    this.contractStartDate = data.contract_start_date;
    this.contractSignDate = data.contract_sign_date;

    this.fillProperties = Boolean(this.properties?.specialism || this.properties?.amountPeople);
  }

  ts() {
    let res = {}
    for (let key in TS) {
      res[TS[key]] = this[key]
    }

    return res
  }
}

export default CompanyModel
