import { useDragLayer } from 'vue3-dnd';
import { toRefs } from '@vueuse/core';

export const useIsDragging = () => {
  const collect = useDragLayer(monitor => ({
    isDragging: monitor.isDragging(),
  }));

  return toRefs(collect).isDragging;
};
