export const LANGUAGES = Object.freeze({
  NETHERLANDS: 'nl',
  FRENCH: 'fr',
  ENGLISH: 'en',
  PORTUGUESE: 'pt',
  BULGARIAN: 'bg',
  TURKISH: 'tr',
  'POLISH': 'pl',
  'UKRAINIAN': 'uk',
  'SPANISH': 'es',
});

export const FLAGS = Object.freeze({
  nl: '🇳🇱',
  fr: '🇫🇷',
  en: '🇬🇧',
  pt: '🇵🇹',
  bg: '🇧🇬',
  tr: '🇹🇷',
  pl: '🇵🇱',
  uk: '🇺🇦',
  es: '🇪🇦',
});

export const ROUTES = Object.freeze({
  PROJECT_INFO: 'projectInfo',
});

export const DEFAULT_PROJECT_STATUS = Object.freeze({
  id: 0,
  color: '#7F848B',
  position: 0,
});

export const INTEGRATION_DETAIL_STATUS = Object.freeze({
  NOT_INTEGRATED: 0,
  INTEGRATED: 1,
  STOPPED: 2,
});

export const INTEGRATION_ACTION_TYPES = Object.freeze({
  CREATE_INVOICE: 'create-new-invoice',
});

export const CANCEL_SOURCE_NAMESPACES = Object.freeze({
  INTEGRATIONS: 'integrations',
  REFERRALS: 'referrals',
});

export const IMPORT_STATUS = Object.freeze({
  PENDING: 0,
  SUCCESS: 1,
  ERROR_PROJECT_NAME_REQUIRED: 2,
  ERROR_PROJECT_RECORD_EXIST: 3,
  ERROR_WRONG_DATETIME: 4,
  ERROR_CANT_CREATE: 5,
  DELETED: 6,
  ERROR_UPDATE: 7,
  ERROR_TEMPLATE_NOT_FOUND: 8,
  ERROR_FORBIDDEN_UPDATE_TEMPLATE: 9,
  ERROR_TEMPLATES_DISABLED: 10,
  ERROR_TASK_PROJECT_NOT_FOUND: 11,
  ERROR_TASK_EXIST: 12
});

export const PROJECT_IMPORT_STATUS = Object.freeze({
  PENDING: 0,
  SUCCESS: 1,
  NAME_REQUIRED: 2,
  RECORD_EXIST: 3,
  WRONG_DATETIME: 4,
  CANT_CREATE: 5,
  DELETED: 6,
  ERROR_UPDATE: 7,
  ERROR_TEMPLATE_NOT_FOUND: 8,
  ERROR_FORBIDDEN_UPDATE_TEMPLATE: 9,
  ERROR_TEMPLATES_DISABLED: 10,
  ERROR_TASK_PROJECT_NOT_FOUND: 11,
});

export const MATERIAL_IMPORT_STATUS = Object.freeze({
  PENDING: 0,
  SUCCESS: 1,
  NAME_REQUIRED: 2,
  RECORD_EXIST: 3,
  CANT_CREATE: 5,
  DELETED: 6
});

export const IMPORT_FILE_STATUS = Object.freeze({
  PENDING: 0,
  SUCCESS: 1,
  ERROR: 2
});

export const IMPORT_FILE_TYPES = Object.freeze({
  PROJECTS: '0',
  REGISTRATION_TYPES: '1',
  TASKS: '2',
  CHECKLIST_AND_CHECKLIST_ITEM: '3'
});

export const IMPORT_PLANNING_TYPES = Object.freeze({
  SUBGROUP: 'subgroup',
  HEADGROUP: 'headgroup',
  NO: 'no',
});

/**
 * @const
 */
export const DATE_PERIODS = Object.freeze({
  SECOND: 'second',
  MINUTE: 'minute',
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year'
});

export const FILTER_CHECKLIST_ITEMS_STATES = Object.freeze({
  CHECKED: 'checked',
  UNCHECKED: 'unchecked',
});

export const FILTER_CHECKLIST_ITEMS_DATES = Object.freeze({
  DUEDATE: 'duedate',
  NO_DUEDATE: 'noDuedate',
});

export const DEFAULT_LABEL_COLOR = '#008148';

export const INTEGRATIONS = Object.freeze({
  EXACT: 'exact-online-project',
  EXACTCOST: 'exact-online-cost',
  WEFACT: 'wefact',
  BOUW7: 'bouw',
  MONEYBIRD: 'moneybird',
  SNELSTART: 'snelstart',
  AFAS: 'afas',
  PROTEUS: 'proteus',
  WOUB_TRANSLATE: 'woub-translate',
  WOUB_FORMS: 'woub-formulieren',
  TIMECHIMP: 'timechimp',
  KINGSOFTWARE: 'kingsoftware',
  TWINFIELD: 'twinfield',
  WOUB_PDF_IMPORTS: 'pdf',
  ONEDRIVE: 'one-drive'
})

export const INTEGRATION_STATIC_ACTIONS = Object.freeze({
  [INTEGRATIONS.WEFACT]: [INTEGRATION_ACTION_TYPES.CREATE_INVOICE],
  [INTEGRATIONS.EXACTCOST]: [INTEGRATION_ACTION_TYPES.CREATE_INVOICE],
  [INTEGRATIONS.MONEYBIRD]: [INTEGRATION_ACTION_TYPES.CREATE_INVOICE],
  [INTEGRATIONS.SNELSTART]: [INTEGRATION_ACTION_TYPES.CREATE_INVOICE],
});

export const CALENDAR_INTEGRATIONS = Object.freeze({
  GOOGLE: 'google-calendar',
  OUTLOOK: 'outlook-calendar',
});

export const INTEGRATION_PAYMENT_PERIOD = Object.freeze({
  PER_TEAM_MEMBER_PER_MONTH: 'perTeamMemberPerMonth',
  PER_MONTH: 'perMonth'
});

export const LOCAL_STORAGE_KEYS = Object.freeze({
  CHECKLISTS: 'checklists',
  LAST_SELECTED_INTEGRATION_FOR_NEW_INVOICE: 'last-selected-integration-for-new-invoice',
  ALERT_REFERRALS: 'alert-referrals',
});

export const FORM_FIELD_TYPES = Object.freeze({
  OPEN_FIELD: 'open_field',
  MULTI_SELECT: 'multi_select',
  CHECKBOX: 'checkbox',
  DROPDOWN: 'dropdown',
  DATE: 'date',
  TIME: 'time',
});

export const FORM_FIELD_OPTION_TYPES = Object.freeze({
  DEFAULT: 'option',
  OTHER: 'other',
  ATTACHMENTS: 'files',
});

export const FORM_EDITOR_CONTEXT_KEY = '$_formEditorContext';
export const FORM_RESPONSE_CONTEXT_KEY = '$_formResponseContext';

export const LINK_REGEXP_PATTERN =
  '(\\b(https?|ftp|file):\\/\\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])';

export const USER_ROLES = Object.freeze({
  MEMBER: 4,
  GUEST: 41,
});

export const DRAGGABLE_TYPES = Object.freeze({
  STATUS_COLUMN: 'STATUS_COLUMN',
  PROJECT_CARD: 'PROJECT_CARD',

  CHECKLIST: 'CHECKLIST',
  PROJECT_CHAT: 'PROJECT_CHAT',
});

export const PROJECT_TABLE_COLUMNS = Object.freeze({
  CREATED_AT: 'createdAt',
  ARCHIVED_AT: 'archivedAt',
  TOTAL_REVENUE: 'totalRevenue',
  TOTAL_COSTS: 'totalCosts',
  HOURS_BUDGET: 'hoursBudget',
  HOURS_REGISTERED_COUNT: 'hoursRegisteredCount',
  HOURS_PLANNED_COUNT: 'hoursPlannedCount',
  HOURS_REMAINING: 'hoursRemaining',
  PROFIT: 'profit',
  PROFIT_PERCENTS: 'profitPercents',
  TOTAL_INVOICED: 'totalInvoiced',
  TOTAL_NOT_INVOICED: 'totalNotInvoiced',
});

export const PROJECT_TABLE_PERMISSIONS_BY_PLAN_ID = Object.freeze({
  [PROJECT_TABLE_COLUMNS.CREATED_AT]: [2, 3, 4],
  [PROJECT_TABLE_COLUMNS.ARCHIVED_AT]: [2, 3, 4],
  [PROJECT_TABLE_COLUMNS.TOTAL_REVENUE]: [3, 4],
  [PROJECT_TABLE_COLUMNS.TOTAL_COSTS]: [3, 4],
  [PROJECT_TABLE_COLUMNS.HOURS_BUDGET]: [3, 4],
  [PROJECT_TABLE_COLUMNS.HOURS_REGISTERED_COUNT]: [3, 4],
  [PROJECT_TABLE_COLUMNS.HOURS_PLANNED_COUNT]: [3, 4],
  [PROJECT_TABLE_COLUMNS.HOURS_REMAINING]: [3, 4],
  [PROJECT_TABLE_COLUMNS.PROFIT]: [3, 4],
  [PROJECT_TABLE_COLUMNS.PROFIT_PERCENTS]: [3, 4],
  [PROJECT_TABLE_COLUMNS.TOTAL_INVOICED]: [2, 3, 4],
  [PROJECT_TABLE_COLUMNS.TOTAL_NOT_INVOICED]: [2, 3, 4],
});

export const REFERRAL_STATUS = Object.freeze({
  OPEN: 'open',
  REGISTERED: 'registered',
  SIGNED: 'signed',
  REVIEW: 'review',
  FULFILLED: 'fulfilled',
});

export const TARIFF_PLAN = Object.freeze({
  WOUB_2: 20,
});

export const PDF_IMPORT_FILE_STATUS = Object.freeze({
  INITIAL: 'INITIAL',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
  PROCESSED: 'PROCESSED',
  FAILED: 'FAILED',
});

export const PDF_IMPORT_FILE_MESSAGE_CODE = Object.freeze({
  PENDING: 0,
  SCANNING: 10,
  SCANNED: 20,
  PROCESSING: 30,
  PROCESSED: 40,
  FAILED: 50,
});

export const MARKETPLACE_CONTACT_URL =
  process.env.VUE_APP_MARKETPLACE_CONTACT_URL ?? 'https://woub.nl/contact-ons/';

export const INVITE_USER_TYPES = Object.freeze({
  MEMBER: 'member',
  EXTERNAL: 'external',
});

export const INVITE_OBJECT_TYPES = Object.freeze({
  USER: 'user',
  EMAIL: 'email',
});
