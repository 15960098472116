import Model from '@/models/Model'
import UserModel from '@/models/User'
import MessageModel from '@/models/Message'

const TYPES = {
  PROJECT: 'project',
  GROUP: 'group',
  PRIVATE: 'private',
  COMPANY: 'company',
}
import {messageTypes} from '@/models/Message.js';

class ChatModel extends Model {
  constructor(data) {
    super(data)

    this.canInviteToChat = data.can_invite_to_chat
    this.canInviteToChatExternal = data.can_invite_to_chat_external
    this.canLeave = data.can_leave
    this.canCreateMessage = Boolean(data.can_create_message)
    this.canCreateChecklist = Boolean(data.can_create_checklist)
    
    for (let key in data) {
      this[key] = data[key]
    }

    this.id = data.id
    this.projectId = data.project_id
    this.name = data.name
    this.status = data.status
    this.users = data.hasOwnProperty('users')
      ? data.users.map(e => new UserModel(e))
      : []

    this.countNewMessages = data.count_new_messages
    this.displayMention = data.display_mention
    this.countUsers = data.count_users

    this.lastMessage = data.last_message
      ? new MessageModel(data.last_message)
      : null
    this.lastMessageUserFirstName = data.last_message_user_first_name || null

    this.checklistTotal = data.checklist_total || 0
    this.checklistChecked = data.checklist_checked || 0

    // Chat types
    this.type = data.type || null
    this.isPrivate = data.chat_type === TYPES.PRIVATE
    this.isProject = data.chat_type === TYPES.PROJECT
    this.isGroup =
      data.chat_type === TYPES.GROUP || data.chat_type === TYPES.COMPANY
    this.isCompany = data.chat_type === TYPES.COMPANY

    this.isShared = data.is_shared

    this.createdAt = this.fixData(data.created_at_utc)
    this.createdAtM = this.toMomentDate(data.created_at_utc)
   
    this.lastMessageAt = data.last_message_at_utc
      ? this.fixData(data.last_message_at_utc)
      : null

    this.lastMessageAtM = data.last_message_at_utc
      ? this.toMomentDate(data.last_message_at_utc)
      : null

    this.muted = data.muted
  }
}

export default ChatModel
